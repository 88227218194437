"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Events = void 0;
var Page_1 = require("../Page");
var Lister_1 = require("../Lister");
var EventObjectInfo_1 = require("./EventObjectInfo");
var Channels_1 = require("./Channels");
var StatusChange_1 = require("./StatusChange");
var EventDeserializer_1 = require("./EventDeserializer");
var ChangeObjectStatusResult_1 = require("./ChangeObjectStatusResult");
var BestAvailableObjects_1 = require("./BestAvailableObjects");
var Events = /** @class */ (function () {
    function Events(client) {
        this.client = client;
        this.channels = new Channels_1.Channels(this.client);
    }
    Events.prototype.create = function (chartKey, params) {
        var requestParameters = {};
        requestParameters.chartKey = chartKey;
        if (params !== undefined) {
            if (params.key !== undefined) {
                requestParameters.eventKey = params.key;
            }
            if (params.tableBookingConfig !== undefined) {
                requestParameters.tableBookingConfig = params.tableBookingConfig;
            }
            if (params.objectCategories !== undefined) {
                requestParameters.objectCategories = params.objectCategories;
            }
            if (params.categories !== undefined) {
                requestParameters.categories = params.categories;
            }
            if (params.name !== undefined) {
                requestParameters.name = params.name;
            }
            if (params.date !== undefined) {
                requestParameters.date = params.date.toString();
            }
            if (params.channels !== undefined) {
                requestParameters.channels = params.channels;
            }
            if (params.forSaleConfig !== undefined) {
                requestParameters.forSaleConfig = params.forSaleConfig;
            }
        }
        return this.client.post('/events', requestParameters)
            .then(function (res) { return new EventDeserializer_1.EventDeserializer().fromJson(res.data); });
    };
    Events.prototype.createMultiple = function (chartKey, events) {
        var requestParameters = {};
        requestParameters.chartKey = chartKey;
        requestParameters.events = [];
        if (events) {
            for (var i = 0; i < events.length; i++) {
                var eventRequest = {};
                var eventParams = events[i];
                if (eventParams.key !== undefined) {
                    eventRequest.eventKey = eventParams.key;
                }
                if (eventParams.tableBookingConfig !== undefined) {
                    eventRequest.tableBookingConfig = eventParams.tableBookingConfig;
                }
                if (eventParams.objectCategories !== undefined) {
                    eventRequest.objectCategories = eventParams.objectCategories;
                }
                if (eventParams.categories !== undefined) {
                    eventRequest.categories = eventParams.categories;
                }
                if (eventParams.name !== undefined) {
                    eventRequest.name = eventParams.name;
                }
                if (eventParams.date !== undefined) {
                    eventRequest.date = eventParams.date.toString();
                }
                if (eventParams.channels !== undefined) {
                    eventRequest.channels = eventParams.channels;
                }
                if (eventParams.forSaleConfig !== undefined) {
                    eventRequest.forSaleConfig = eventParams.forSaleConfig;
                }
                requestParameters.events.push(eventRequest);
            }
        }
        return this.client.post('/events/actions/create-multiple', requestParameters)
            .then(function (res) {
            var result = [];
            var deserializer = new EventDeserializer_1.EventDeserializer();
            for (var _i = 0, _a = res.data.events; _i < _a.length; _i++) {
                var event_1 = _a[_i];
                result.push(deserializer.fromJson(event_1));
            }
            return result;
        });
    };
    Events.prototype.retrieve = function (eventKey) {
        return this.client.get("/events/".concat(encodeURIComponent(eventKey)))
            .then(function (res) { return new EventDeserializer_1.EventDeserializer().fromJson(res.data); });
    };
    Events.prototype.update = function (eventKey, params) {
        var requestParameters = {};
        if (params.chartKey !== undefined) {
            requestParameters.chartKey = params.chartKey;
        }
        if (params.key !== undefined) {
            requestParameters.eventKey = params.key;
        }
        if (params.tableBookingConfig !== undefined) {
            requestParameters.tableBookingConfig = params.tableBookingConfig;
        }
        if (params.objectCategories !== undefined) {
            requestParameters.objectCategories = params.objectCategories;
        }
        if (params.categories !== undefined) {
            requestParameters.categories = params.categories;
        }
        if (params.name !== undefined) {
            requestParameters.name = params.name;
        }
        if (params.date !== undefined) {
            requestParameters.date = params.date.toString();
        }
        if (params.isInThePast !== undefined) {
            requestParameters.isInThePast = params.isInThePast;
        }
        return this.client.post("events/".concat(encodeURIComponent(eventKey)), requestParameters);
    };
    Events.prototype.delete = function (eventKey) {
        return this.client.delete("/events/".concat(encodeURIComponent(eventKey)));
    };
    Events.prototype.listAll = function (requestParameters) {
        if (requestParameters === void 0) { requestParameters = {}; }
        return this.iterator().all(requestParameters);
    };
    Events.prototype.listFirstPage = function (pageSize) {
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().firstPage(null, pageSize);
    };
    Events.prototype.listPageAfter = function (afterId, pageSize) {
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().pageAfter(afterId, null, pageSize);
    };
    Events.prototype.listPageBefore = function (beforeId, pageSize) {
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().pageBefore(beforeId, null, pageSize);
    };
    Events.prototype.iterator = function () {
        return new Lister_1.Lister('/events', this.client, 'events', function (data) {
            var events = data.items.map(function (eventData) { return new EventDeserializer_1.EventDeserializer().fromJson(eventData); });
            return new Page_1.Page(events, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    Events.prototype.statusChanges = function (eventKey) {
        return new Lister_1.Lister("/events/".concat(encodeURIComponent(eventKey), "/status-changes"), this.client, 'statusChanges', function (data) {
            var statusChanges = data.items.map(function (statusChangesData) { return new StatusChange_1.StatusChange(statusChangesData); });
            return new Page_1.Page(statusChanges, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    Events.prototype.statusChangesForObject = function (eventKey, objectId) {
        return new Lister_1.Lister("/events/".concat(encodeURIComponent(eventKey), "/objects/").concat(encodeURIComponent(objectId), "/status-changes"), this.client, 'statusChanges', function (data) {
            var statusChanges = data.items.map(function (statusChangesData) { return new StatusChange_1.StatusChange(statusChangesData); });
            return new Page_1.Page(statusChanges, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    Events.prototype.markAsForSale = function (eventKey, objects, areaPlaces, categories) {
        if (objects === void 0) { objects = null; }
        if (areaPlaces === void 0) { areaPlaces = null; }
        if (categories === void 0) { categories = null; }
        var requestParameters = {};
        if (objects !== null) {
            requestParameters.objects = objects;
        }
        if (areaPlaces !== null) {
            requestParameters.areaPlaces = areaPlaces;
        }
        if (categories !== null) {
            requestParameters.categories = categories;
        }
        return this.client.post("events/".concat(encodeURIComponent(eventKey), "/actions/mark-as-for-sale"), requestParameters);
    };
    Events.prototype.markAsNotForSale = function (eventKey, objects, areaPlaces, categories) {
        if (objects === void 0) { objects = null; }
        if (areaPlaces === void 0) { areaPlaces = null; }
        if (categories === void 0) { categories = null; }
        var requestParameters = {};
        if (objects !== null) {
            requestParameters.objects = objects;
        }
        if (areaPlaces !== null) {
            requestParameters.areaPlaces = areaPlaces;
        }
        if (categories !== null) {
            requestParameters.categories = categories;
        }
        return this.client.post("events/".concat(encodeURIComponent(eventKey), "/actions/mark-as-not-for-sale"), requestParameters);
    };
    Events.prototype.markEverythingAsForSale = function (eventKey) {
        return this.client.post("events/".concat(encodeURIComponent(eventKey), "/actions/mark-everything-as-for-sale"));
    };
    Events.prototype.overrideSeasonObjectStatus = function (eventKey, objects) {
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/actions/override-season-status"), { objects: objects });
    };
    Events.prototype.useSeasonObjectStatus = function (eventKey, objects) {
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/actions/use-season-status"), { objects: objects });
    };
    Events.prototype.updateExtraData = function (eventKey, obj, extraData) {
        var requestParameters = {};
        requestParameters.extraData = extraData;
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/objects/").concat(encodeURIComponent(obj), "/actions/update-extra-data"), requestParameters);
    };
    Events.prototype.updateExtraDatas = function (eventKey, extraData) {
        var requestParameters = {};
        requestParameters.extraData = extraData;
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/actions/update-extra-data"), requestParameters);
    };
    Events.prototype.retrieveObjectInfo = function (eventKey, label) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveObjectInfos(eventKey, [label])];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result[label]];
                }
            });
        });
    };
    Events.prototype.retrieveObjectInfos = function (eventKey, labels) {
        var params = new URLSearchParams();
        labels.forEach(function (label) {
            params.append('label', label);
        });
        return this.client.get("events/".concat(encodeURIComponent(eventKey), "/objects"), { params: params })
            .then(function (res) {
            var objectInfos = res.data;
            for (var _i = 0, _a = Object.keys(objectInfos); _i < _a.length; _i++) {
                var key = _a[_i];
                objectInfos[key] = new EventObjectInfo_1.EventObjectInfo(objectInfos[key]);
            }
            return objectInfos;
        });
    };
    Events.prototype.changeObjectStatus = function (eventKeyOrKeys, objectOrObjects, status, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys, allowedPreviousStatuses, rejectedPreviousStatuses) {
        if (holdToken === void 0) { holdToken = null; }
        if (orderId === void 0) { orderId = null; }
        if (keepExtraData === void 0) { keepExtraData = null; }
        if (ignoreChannels === void 0) { ignoreChannels = null; }
        if (channelKeys === void 0) { channelKeys = null; }
        if (allowedPreviousStatuses === void 0) { allowedPreviousStatuses = null; }
        if (rejectedPreviousStatuses === void 0) { rejectedPreviousStatuses = null; }
        var request = this.changeObjectStatusRequest(objectOrObjects, status, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys, allowedPreviousStatuses, rejectedPreviousStatuses);
        request.events = Array.isArray(eventKeyOrKeys) ? eventKeyOrKeys : [eventKeyOrKeys];
        return this.client.post('/events/groups/actions/change-object-status?expand=objects', request)
            .then(function (res) { return new ChangeObjectStatusResult_1.ChangeObjectStatusResult(res.data.objects); });
    };
    Events.prototype.changeObjectStatusInBatch = function (statusChangeRequests) {
        var _this = this;
        var requests = statusChangeRequests.map(function (r) {
            var json = _this.changeObjectStatusRequest(r.objectOrObjects, r.status, r.holdToken, r.orderId, r.keepExtraData, r.ignoreChannels, r.channelKeys, r.allowedPreviousStatuses, r.rejectedPreviousStatuses);
            json.event = r.eventKey;
            return json;
        });
        var request = { statusChanges: requests };
        return this.client.post('/events/actions/change-object-status?expand=objects', request)
            .then(function (res) { return res.data.results.map(function (r) { return new ChangeObjectStatusResult_1.ChangeObjectStatusResult(r.objects); }); });
    };
    Events.prototype.changeObjectStatusRequest = function (objectOrObjects, status, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys, allowedPreviousStatuses, rejectedPreviousStatuses) {
        if (channelKeys === void 0) { channelKeys = null; }
        if (allowedPreviousStatuses === void 0) { allowedPreviousStatuses = null; }
        if (rejectedPreviousStatuses === void 0) { rejectedPreviousStatuses = null; }
        var request = {};
        request.objects = this.normalizeObjects(objectOrObjects);
        request.status = status;
        if (holdToken !== null) {
            request.holdToken = holdToken;
        }
        if (orderId !== null) {
            request.orderId = orderId;
        }
        if (keepExtraData !== null) {
            request.keepExtraData = keepExtraData;
        }
        if (ignoreChannels !== null) {
            request.ignoreChannels = ignoreChannels;
        }
        if (channelKeys !== null) {
            request.channelKeys = channelKeys;
        }
        if (allowedPreviousStatuses !== null) {
            request.allowedPreviousStatuses = allowedPreviousStatuses;
        }
        if (rejectedPreviousStatuses !== null) {
            request.rejectedPreviousStatuses = rejectedPreviousStatuses;
        }
        return request;
    };
    Events.prototype.book = function (eventKeyOrKeys, objectOrObjects, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys) {
        if (holdToken === void 0) { holdToken = null; }
        if (orderId === void 0) { orderId = null; }
        if (keepExtraData === void 0) { keepExtraData = null; }
        if (ignoreChannels === void 0) { ignoreChannels = null; }
        if (channelKeys === void 0) { channelKeys = null; }
        return this.changeObjectStatus(eventKeyOrKeys, objectOrObjects, EventObjectInfo_1.EventObjectInfo.BOOKED, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys);
    };
    Events.prototype.bookBestAvailable = function (eventKey, bestAvailableParams, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys) {
        if (holdToken === void 0) { holdToken = null; }
        if (orderId === void 0) { orderId = null; }
        if (keepExtraData === void 0) { keepExtraData = null; }
        if (ignoreChannels === void 0) { ignoreChannels = null; }
        if (channelKeys === void 0) { channelKeys = null; }
        return this.changeBestAvailableObjectStatus(encodeURIComponent(eventKey), bestAvailableParams, EventObjectInfo_1.EventObjectInfo.BOOKED, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys);
    };
    Events.prototype.release = function (eventKeyOrKeys, objectOrObjects, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys) {
        if (holdToken === void 0) { holdToken = null; }
        if (orderId === void 0) { orderId = null; }
        if (keepExtraData === void 0) { keepExtraData = null; }
        if (ignoreChannels === void 0) { ignoreChannels = null; }
        if (channelKeys === void 0) { channelKeys = null; }
        return this.changeObjectStatus(eventKeyOrKeys, objectOrObjects, EventObjectInfo_1.EventObjectInfo.FREE, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys);
    };
    Events.prototype.hold = function (eventKeyOrKeys, objectOrObjects, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys) {
        if (orderId === void 0) { orderId = null; }
        if (keepExtraData === void 0) { keepExtraData = null; }
        if (ignoreChannels === void 0) { ignoreChannels = null; }
        if (channelKeys === void 0) { channelKeys = null; }
        return this.changeObjectStatus(eventKeyOrKeys, objectOrObjects, EventObjectInfo_1.EventObjectInfo.HELD, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys);
    };
    Events.prototype.holdBestAvailable = function (eventKey, bestAvailableParams, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys) {
        if (orderId === void 0) { orderId = null; }
        if (keepExtraData === void 0) { keepExtraData = null; }
        if (ignoreChannels === void 0) { ignoreChannels = null; }
        if (channelKeys === void 0) { channelKeys = null; }
        return this.changeBestAvailableObjectStatus(encodeURIComponent(eventKey), bestAvailableParams, EventObjectInfo_1.EventObjectInfo.HELD, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys);
    };
    Events.prototype.changeBestAvailableObjectStatus = function (eventKey, bestAvailableParams, status, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys) {
        if (holdToken === void 0) { holdToken = null; }
        if (orderId === void 0) { orderId = null; }
        if (keepExtraData === void 0) { keepExtraData = null; }
        if (ignoreChannels === void 0) { ignoreChannels = null; }
        if (channelKeys === void 0) { channelKeys = null; }
        var requestParameters = {};
        requestParameters.status = status;
        if (holdToken !== null) {
            requestParameters.holdToken = holdToken;
        }
        if (orderId !== null) {
            requestParameters.orderId = orderId;
        }
        if (keepExtraData !== null) {
            requestParameters.keepExtraData = keepExtraData;
        }
        if (ignoreChannels !== null) {
            requestParameters.ignoreChannels = ignoreChannels;
        }
        if (channelKeys !== null) {
            requestParameters.channelKeys = channelKeys;
        }
        requestParameters.bestAvailable = bestAvailableParams;
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/actions/change-object-status"), requestParameters)
            .then(function (res) { return new BestAvailableObjects_1.BestAvailableObjects(res.data); });
    };
    Events.prototype.normalizeObjects = function (objectOrObjects) {
        if (Array.isArray(objectOrObjects)) {
            if (objectOrObjects.length === 0) {
                return [];
            }
            return objectOrObjects.map(function (obj) {
                if (typeof obj === 'object') {
                    return obj;
                }
                return { objectId: obj };
            });
        }
        // @ts-ignore
        return this.normalizeObjects([objectOrObjects]);
    };
    return Events;
}());
exports.Events = Events;
