"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventObjectInfo = void 0;
var helperFunctions_1 = require("../utilities/helperFunctions");
var IDs_1 = require("../Common/IDs");
var Floor_1 = require("../Common/Floor");
var EventObjectInfo = /** @class */ (function () {
    function EventObjectInfo(json) {
        this.label = json.label;
        this.labels = helperFunctions_1.HelperFunctions.labelCreator(json);
        this.ids = new IDs_1.IDs(json.ids.own, json.ids.parent, json.ids.section);
        this.status = json.status;
        this.categoryLabel = json.categoryLabel;
        this.categoryKey = json.categoryKey;
        this.ticketType = json.ticketType;
        this.entrance = json.entrance;
        this.objectType = json.objectType;
        this.section = json.section;
        this.orderId = json.orderId;
        this.forSale = json.forSale;
        this.holdToken = json.holdToken;
        this.capacity = json.capacity;
        this.bookAsAWhole = json.bookAsAWhole;
        this.numBooked = json.numBooked;
        this.numFree = json.numFree;
        this.numHeld = json.numHeld;
        this.numNotForSale = json.numNotForSale;
        this.extraData = json.extraData;
        this.isAccessible = json.isAccessible;
        this.isCompanionSeat = json.isCompanionSeat;
        this.hasRestrictedView = json.hasRestrictedView;
        this.displayedObjectType = json.displayedObjectType;
        this.leftNeighbour = json.leftNeighbour;
        this.rightNeighbour = json.rightNeighbour;
        this.isAvailable = json.isAvailable;
        this.availabilityReason = json.availabilityReason;
        this.channel = json.channel;
        this.distanceToFocalPoint = json.distanceToFocalPoint;
        this.holds = json.holds;
        this.numSeats = json.numSeats;
        this.variableOccupancy = json.variableOccupancy;
        this.minOccupancy = json.minOccupancy;
        this.maxOccupancy = json.maxOccupancy;
        this.seasonStatusOverriddenQuantity = json.seasonStatusOverriddenQuantity;
        this.zone = json.zone;
        this.floor = json.floor ? new Floor_1.Floor(json.floor.name, json.floor.displayName) : undefined;
    }
    EventObjectInfo.FREE = 'free';
    EventObjectInfo.BOOKED = 'booked';
    EventObjectInfo.HELD = 'reservedByToken';
    return EventObjectInfo;
}());
exports.EventObjectInfo = EventObjectInfo;
